import React from "react";
// Customizable Area Start
import DashboardController, { Props } from "./DashboardController";
import './style.css';
import { withTheme, withStyles, createStyles, Theme, createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    Grid,
    Typography,
    Box,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Modal,
    FormControlLabel,
    Switch,

} from "@material-ui/core"
import UppNav from "../../../components/src/UppNav"
import { notesData, fakeNotesType, fakeImageData } from "../../../components/src/Utils"
import CustomBar from "../../../components/src/Bar"
import ListBox from "../../../components/src/ListBox";
// @ts-ignore
import CSImage from "../assets/Alexa.png";
// @ts-ignore
import PSImage from "../assets/Lisa.png";
// @ts-ignore
import MyImage from "../assets/gaurav.png";
import EngagementBox from "../../../components/src/EngagementBox";
import JoinMeetingPopup from "../../../components/src/JoinMeetingPopup";
import TableData from "../../../components/src/TableData";
import TableData2 from "../../../components/src/TableData2";
// @ts-ignore

// Customizable Area End

class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start


    // Customizable Area End

    render() {
        // Customizable Area Start
        {/* @ts-ignore */ }
        const { tabs, join, meetingStatus,eventType, matricsResponse, roleTypes, analystcs_data, boxOpen, boxEngOpen,participant_data,emotionType, showTooltip, disclaimerSwitch } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            /* @ts-ignore */
            <Box sx={{ overflow: { xs: "auto", sm: "auto", md: "hidden" } }}>
                {/* @ts-ignore */}
                <Modal
                    data-testid="ModalBox"
                    open={join}
                    onClose={() => this.calcelJoin()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {/* @ts-ignore */}
                    <Box >
                        <JoinMeetingPopup
                            /* @ts-ignore */
                            meetingTypesData={this.state.meetingTypes}
                            cancelJoin={this.calcelJoin}
                            getRoleFunc={this.getRoleType}
                            roleTypesData={roleTypes}
                            joinMeetingAxios={this.joinMeetingAxios}
                        />
                    </Box>
                </Modal>
                <Box style={{position:'relative'}}>
                    <UppNav meetingIsInProgress={meetingStatus} totalParticipants={matricsResponse.total_participants} seconds={matricsResponse.meeting_duration_seconds} handleJoin={this.handleJoin} handleClick={this.leaveMeetingAxios} data-testid="handleClick" />
                    
                    {showTooltip && (
                    <Typography component="div" style={selectDropdownStyles}>
                        <ThemeProvider theme={theme}>
                            <FormControlLabel
                                control={<Switch checked={disclaimerSwitch} onChange={this.handleSwitchChange} name="disclaimerSwitch" color="default" />}
                                label="Disclose my Emotions to meeting participants"
                            />
                        </ThemeProvider>
                        <Typography style={webStyle.label}>
                            <img src={require("../assets/tooltip_square.png")} height="15px" width="15px" style={{margin:'4px 13px 0 7px'}} />
                            <Typography>
                                Before deciding to disclose your emotions to meeting participants, consider the purpose of the meeting and the 
                                relationships between the participants. If you do decide to share your emotions, be clear, mindful, and open to 
                                feedback.
                            </Typography>
                        </Typography>
                    </Typography>
                    )}
                </Box>

                <Grid container style={{ backgroundColor: "rgb(42 42 42)", minHeight: "calc(100vh - 50px)", overflow: "hidden" }}>
                    <Grid item xs={12} md={4} style={{ borderRight: "2px solid rgb(38 38 37)" }}>
                        {/* @ts-ignore */}
                        <Box style={{ padding: "20px", height: "calc(100vh - 225px)", overflowY: "auto", overflowX: "hidden" }}>
                            <EngagementBox most={(this.findMostEngagedImages()).length !== 0 ? this.findMostEngagedImages() : fakeImageData.most} less={(this.findLeastEngagedImages()).length == 0 ? fakeImageData.less : this.findLeastEngagedImages()} engagement={matricsResponse?.average_engagement} boxEngOpen={boxEngOpen} handleBoxEngOpen={this.handleBoxEngOpen} />
                            <ListBox listName="My Participation" pName={Object.keys(this.findMyParticipant()).length == 0 ? "Gaurav (Participant)" : this.findMyParticipant().participant_name} pImg={Object.keys(this.findMyParticipant()).length == 0 ? MyImage : `data:image/png;base64,${this.findMyParticipant().profile_image_bytes}`} progress={matricsResponse.my_participation.engagement_percentile} feeling={this.getFeeling(matricsResponse.my_participation.emotion_id)} boxOpen={boxOpen[0]} handleBoxOpen={this.handleBoxOpen} />
                            <ListBox listName="Current Speaker" pName={Object.keys(this.findMyCurrentSpeaker()).length == 0 ? "Alexa Tenorio" : this.findMyCurrentSpeaker().participant_name} pImg={Object.keys(this.findMyCurrentSpeaker()).length == 0 ? CSImage : `data:image/png;base64,${this.findMyCurrentSpeaker().profile_image_bytes}`} progress={matricsResponse?.current_speaker?.energy_level} feeling={this.getFeeling(matricsResponse?.current_speaker?.emotion_id)} boxOpen={boxOpen[1]} handleBoxOpen={this.handleBoxOpen} />
                            <ListBox listName="Previous Speaker" pName={Object.keys(this.findMyPreviousSpeaker()).length == 0 ? "Lisanne Viscaal" : this.findMyPreviousSpeaker().participant_name} pImg={Object.keys(this.findMyPreviousSpeaker()).length == 0 ? PSImage : `data:image/png;base64,${this.findMyPreviousSpeaker().profile_image_bytes}`} progress={matricsResponse?.previous_speaker?.energy_level} feeling={this.getFeeling(matricsResponse?.previous_speaker?.emotion_id)} boxOpen={boxOpen[2]} handleBoxOpen={this.handleBoxOpen} />
                        </Box>
                        {/* @ts-ignore */}
                        <Box style={{ backgroundColor: "rgb(48 48 48)" }}>
                            {/* @ts-ignore */}
                            <Box style={{ padding: " 15px 20px 15px 20px", }}>
                                {/* @ts-ignore */}
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    {/* @ts-ignore */}
                                    <Box style={{ display: "flex", gap: "5px", alignItems: "center", height: "70px", padding: "5px", width: "90%", backgroundColor: "rgb(28, 27, 27)", border: "1px solid rgb(72, 72, 72)", borderRadius: "10px" }}>
                                        {/* @ts-ignore */}
                                        <Box style={{ marginLeft: "10px", marginRight: "10px" }}>
                                            <Typography style={{ color: "white", fontFamily: "SFPro-Bold", fontSize: "16px" }}>
                                                <span style={{ color: "#0CC8A8" }}>Tip:</span> {matricsResponse.my_participation.tip.text}</Typography>
                                        </Box>
                                    </Box>
                                    {/* @ts-ignore */}
                                    <Box>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <img src={require("../assets/participation.png")} height="20px" width="20px" />
                                        </div>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8} className="boxGrid8pad" style={{ padding: "20px" }}>
                        <Grid container>
                            {/* @ts-ignore */}
                            <Grid item sm={12} className="tabsBox">
                                <Tabs
                                    className="tabs_main"
                                    value={tabs}
                                    onChange={this.handleTabsChange}>
                                    <Tab className="headingTabs" label="ANALYTICS" />
                                    <Tab className="headingTabs" label="PARTICIPANTS" />
                                </Tabs>
                            </Grid>
                            <Grid item sm={12} style={{ width: "100%" }}>
                                {tabs === 0 &&
                                    <TableContainer className="tableStyle" component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderRight: "1px solid rgb(72 72 72)", borderBottomColor: "rgb(72 72 72)" }} align="center">{"Time"}</TableCell>
                                                    <TableCell style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderRight: "1px solid rgb(72 72 72)", borderBottomColor: "rgb(72 72 72)" }} align="center" colSpan={2}>{"Event"}</TableCell>
                                                    <TableCell style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderBottomColor: "rgb(72 72 72)" }} align="center" colSpan={4}>{"Description"}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableData matricsResponse={matricsResponse} eventType={eventType} findIcon={this.findIcon} analystcs_data={analystcs_data} />
                                            </TableBody>
                                        </Table>
                                    </TableContainer>}
                                {tabs === 1 &&
                                    <TableContainer component={Paper} className="tableStyle">
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderRight: "1px solid rgb(72 72 72)", borderBottomColor: "rgb(72 72 72)" }} align="center">{"Participants"}</TableCell>
                                                    <TableCell style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderRight: "1px solid rgb(72 72 72)", borderBottomColor: "rgb(72 72 72)" }} align="center">{"Engagement"}</TableCell>
                                                    <TableCell style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderRight: "1px solid rgb(72 72 72)", borderBottomColor: "rgb(72 72 72)" }} align="center">{"Energy Meter"}</TableCell>
                                                    <TableCell style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderRight: "1px solid rgb(72 72 72)", borderBottomColor: "rgb(72 72 72)" }} align="center" colSpan={2}>{"Tips/Kudos"}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableData2 MyImage={MyImage} matricsResponse={matricsResponse} emotionType={emotionType} eventType={eventType} getFeeling2={this.getFeeling2} participant_data={participant_data} />

                                        </Table>
                                    </TableContainer>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export { Dashboard }

export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({

            whiteBorder: {
                width: "1px",
                height: "100vh",
                backgroundColor: "black",
            },
        }))(Dashboard)
);
const webStyle ={
    
    label:{
        color:'rgb(213, 213, 213)',
        fontSize:11,
        fontFamily:'SFPro-Regular',
        marginTop:5,
        display:'flex',
        alignItems:'flex-start',

    },
}
const selectDropdownStyles={
    backgroundColor: "rgb(28, 27, 27)",
        border:'1px solid rgb(72, 72, 72)',
        borderRadius:10,
        padding:8,
        position:"absolute",
        top:'75%',
        right:18,
        zIndex:99,
        width:320,
        
  } as const;

const theme = createTheme({
    overrides: {
        MuiTypography:{
            body1:{
                color:'#fff',
                fontSize:12,
                fontFamily:'SFPro-Bold'  
            }
        },
        MuiFormControlLabel:{
            root:{
                marginLeft:0,
            }
        },
        MuiSwitch:{
            root:{
                width: 27,
                height: 15,
                padding: 0,
                border:'1px solid #0CC8A8',
                borderRadius:10,
                marginRight:8,
            },
            thumb:{
                width:10,
                height:10,
                backgroundColor:'#0CC8A8',
            },
            switchBase:{
                padding:2,
                '&.Mui-checked': {
                    transform: 'translateX(11px)'
                },
            }
        }
    },
  });

// Customizable Area End
