export const Images = {
  energyrise: require("../Images/events/chevron_double_up.png"),
  energydrop: require("../Images/events/chevron_double_down.png"),
  engagementrise: require("../Images/events/trending_up.png"),
  engagementdrop: require("../Images/events/trending_down.png"),
  keymoment: require("../Images/events/key_moment.png"),
  miscelaneous: require("../Images/events/miscelaneous.png"),
  tip: require("../Images/events/tip.png"),
  kudos: require("../Images/events/thumbs_up.png"),
  MyImage: require("../assets/gaurav.png"),
};

export const Emojis = {
    Engaging: require("../Images/smileypack/Engaging.png"),
    Relaxed: require("../Images/smileypack/Relaxed.png"),
    Anxious: require("../Images/smileypack/Anxious.png"),
    Calm: require("../Images/smileypack/Calm.png"),
    Cheerful: require("../Images/smileypack/Cheerful.png"),
    Confident: require("../Images/smileypack/Confident.png"),
    Disengaged: require("../Images/smileypack/Disengaged.png"),
    Doubtful: require("../Images/smileypack/Doubtful.png"),
    Nervous: require("../Images/smileypack/Nervous.png"),
    Passionate: require("../Images/smileypack/Passionate.png"),
    Pleased: require("../Images/smileypack/Pleased.png"),
    Pleasant : require("../Images/smileypack/Pleased.png"),
    Pondering: require("../Images/smileypack/Pondering.png"),
    Assuring: require("../Images/smileypack/Assuring.png"),
    Restless: require("../Images/smileypack/Restless.png"),
    Reasoning:require("../Images/smileypack/Thinking.png"),
    Silence: require("../Images/smileypack/Silence.png"),
    Suprised: require("../Images/smileypack/Suprised.png"),
    Tense: require("../Images/smileypack/Tense.png"),
    Thinking: require("../Images/smileypack/Thinking.png"),
    Tired: require("../Images/smileypack/Tired.png"),
    Weary: require("../Images/smileypack/Weary.png")
}