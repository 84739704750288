import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// import axios from "axios";
// Customizable Area End
export const configJSON = require("./config.js");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
    location: any;
    history: any;
    match: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  imageLoading: boolean;
  password: boolean;
  email: string;
  windowWidth: number;
  loading: boolean;
  signInErrMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JoinMeetingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  LoginAPi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // getName(MessageEnum.NavigationToSingleArticle),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      imageLoading: true,
      password: true,
      email: "",
      windowWidth: window?.innerWidth,
      loading: false,
      signInErrMsg: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

 
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleImageLoading = () => {
    this.setState({ imageLoading: false });
  };

  signInAxios = async (values: any) => {
    
    this.setState({ loading: true });
    await fetch(
        `https://pd-mirro-api.gentledune-75b0834d.eastus.azurecontainerapps.io/Login?username=${values.email}&password=${values.password}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response: any) => {
        return response.json();
       
      }).then((data: any)=>{
        console.log(data)
        if (data?.length) {
          localStorage.setItem('User', 'true');
          localStorage.setItem('userDetails', JSON.stringify(data))
          this.setState({
            loading: true,
          });
          this.props.navigation.navigate("DashboardPage");
        }
      })
      .catch((error: any) => {
        this.setState({
          loading: true,
          signInErrMsg: error,
        });
      });
  };
  // Customizable Area End
}
